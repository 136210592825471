import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    setGameLoadComplete,
    setSpinInProgress,
    setLoadingPercent,
    setAudioEnabled,
    setDismissErrorMessage,
    setInGameError,
    setGameWindowExpansionDisabled
} from '../../../../redux/actions/game-window';
import { setGameClientStatus } from '../../../../redux/actions/gameClientStatus';
import { useExit } from '../../../../common/hooks/use-exit/use-exit';
import { providerSelector } from '../../../../selectors/gameContext';
import { depositLinkSelector } from '../../../../selectors/game-window';
import { sendDepositMessage } from '../../../../common/helpers/send-deposit-message';

export const useGameClientHandlers = () => {
    const dispatch = useDispatch();
    const exitGame = useExit();
    const depositLink = useSelector(depositLinkSelector);
    const provider = useSelector(providerSelector);

    const ddLogProviderError = useCallback(
        (payload: ProviderErrorPayload) => {
            datadogRum.addError(new Error('GW_IN_GAME_ERROR'), { provider: provider?.toUpperCase(), ...payload });
        },
        [provider]
    );

    const handleCloseGameLauncher = useCallback(() => {
        exitGame();
    }, [exitGame]);

    const handleGameLoaded = useCallback(() => {
        dispatch(setGameLoadComplete(true));
    }, [dispatch]);

    // Handler to account for the handshake event sent by game-client. This will allow to send the launch params only when the client has finished mounting.
    const handleGameClientHandshake = () => {
        dispatch(setGameClientStatus(true));
    };

    const handleGameLoadStarted = useCallback(() => {
        dispatch(setGameLoadComplete(false));
    }, [dispatch]);

    const handleSpinInProgress = useCallback(
        (payload: boolean) => {
            dispatch(setSpinInProgress(payload));
            dispatch(setGameWindowExpansionDisabled(payload));
        },
        [dispatch]
    );

    const handleLoadProgress = useCallback(
        (payload: number) => {
            dispatch(setLoadingPercent(payload));
        },
        [dispatch]
    );

    const handleAudioEnabled = useCallback(
        (payload: boolean) => {
            dispatch(setAudioEnabled(payload));
        },
        [dispatch]
    );

    const handleResetGame = useCallback(() => {
        window.location.reload();
    }, []);

    // Function to handle any "SHOW_FUNDS" message from GC initiated by provider. This will redirect to a new tab with the account page from our side.
    const handleShowFunds = useCallback(() => {
        window.open(depositLink);

        sendDepositMessage(depositLink);
    }, [depositLink]);

    const handleProviderError = useCallback(
        (payload: ProviderErrorPayload) => {
            if (!payload.display) {
                datadogRum.addAction('GW_ERROR_POPUP_DISMISSED');
                return dispatch(setDismissErrorMessage());
            }

            dispatch(
                setInGameError({
                    errorCode: payload.errorCode,
                    errorMessage: payload.errorMessage,
                    errorType: payload.errorType,
                    errorParameters: payload?.errorParameters
                })
            );

            ddLogProviderError(payload);
        },
        [dispatch, ddLogProviderError]
    );

    return {
        handleGameClientHandshake,
        handleCloseGameLauncher,
        handleGameLoadStarted,
        handleLoadProgress,
        handleGameLoaded,
        handleResetGame,
        handleAudioEnabled,
        handleSpinInProgress,
        handleProviderError,
        handleShowFunds
    };
};

export type ProviderErrorPayload = {
    display: boolean | unknown;
    errorCode: string;
    errorMessage: string;
    errorType: string;
    errorParameters: Record<string, unknown>;
};
